var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-outer"},[_c('header',{class:[
      _vm.textColorOnTop,
      { fixed: _vm.ifFixed || _vm.ifSubnavOpen }
    ]},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":require("@/assets/img/public/01_header/logo.svg"),"alt":"Kirin Engineering"}})]),_c('nav',{class:[
          {'active' : _vm.ifHamburgerOpen}
        ]},[_c('div',{staticClass:"list"},[_vm._l((_vm.headerNav),function(item,i){return [(item.subs)?_c('div',{key:("headerMainNav" + i),staticClass:"nav-item p hassub",on:{"click":function($event){return _vm.headerMainNavClick(i);}}},[_vm._v(" "+_vm._s(item.title)+" ")]):(item.route)?_c('router-link',{key:("headerMainNav" + i),staticClass:"nav-item p",attrs:{"to":item.route},nativeOn:{"click":function($event){return _vm.headerMainNavClick(i);}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.subs)?_c('div',{key:("headerRwdSubNav" + i),staticClass:"rwdsubbox",class:[{active: _vm.rwdSubOpenIndex === i}]},_vm._l((item.subs),function(item,i){return _c('router-link',{key:("headerRwdSubNavItem" + i),staticClass:"txt-white",attrs:{"to":item.route}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()]})],2),_c('div',{staticClass:"txt-right"},[_c('div',{staticClass:"lang_link"},[_c('router-link',{staticClass:"small active",attrs:{"to":{ name: 'Home' }}},[_c('span',[_vm._v(" 繁體中文 ")])]),_vm._m(0),_vm._m(1)],1)])]),_c('div',{staticClass:"hamburger",class:[
          {
            'w': _vm.ifHamburgerOpen
          }
        ],on:{"click":function($event){return _vm.toggleHamburger()}}},[_c('div'),_c('div'),_c('div')])],1),_c('div',{staticClass:"header-subcover",class:{active: _vm.ifSubnavOpen},on:{"click":function($event){return _vm.closeSubNav()}}}),_c('div',{staticClass:"header-subblock",class:{active: _vm.ifSubnavOpen}},[_c('div',{staticClass:"pic"},[_c('figure',{style:({
            backgroundImage: ("url(" + _vm.headerSubPic + ")")
          })})]),_c('div',{staticClass:"sub-nav"},_vm._l((_vm.headerSubNav),function(item,i){return _c('router-link',{key:("headerSubNav" + i),staticClass:"item",attrs:{"to":item.route},nativeOn:{"click":function($event){return _vm.closeSubNav()}}},[_c('div',{staticClass:"item-text"},[_c('h6',[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"txt-gray"},[_vm._v(_vm._s(item.enName))])]),_c('div',{staticClass:"arrow-circle"})])}),1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"small",attrs:{"href":"https://www.kirineng.co.jp/","target":"_blank"}},[_c('span',[_vm._v(" 日本語 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"small",attrs:{"href":"https://www.kirineng.co.jp/corporate/global/en_about.html","target":"_blank"}},[_c('span',[_vm._v(" ENGLISH ")])])}]

export { render, staticRenderFns }