<template>
  <div class="header-outer">
    <header
      :class="[
        textColorOnTop,
        { fixed: ifFixed || ifSubnavOpen }
      ]">
        <router-link
          class="logo"
          :to="{ name: 'Home' }">
            <img
              src="@/assets/img/public/01_header/logo.svg"
              alt="Kirin Engineering">
        </router-link>

        <nav
          :class="[
            {'active' : ifHamburgerOpen}
          ]">
            <div class="list">
              <template
                v-for="(item, i) in headerNav">
                <!-- 有子選單 -->
                <div
                  class="nav-item p hassub"
                  v-if="item.subs"
                  :key="`headerMainNav${i}`"
                  @click="headerMainNavClick(i);">
                    {{item.title}}
                </div>
                <!-- 有route連結 -->
                <router-link
                  class="nav-item p"
                  v-else-if="item.route"
                  :key="`headerMainNav${i}`"
                  :to="item.route"
                  @click.native="headerMainNavClick(i);">
                    {{item.title}}
                </router-link>

                <div
                  v-if="item.subs"
                  :key="`headerRwdSubNav${i}`"
                  class="rwdsubbox"
                  :class="[{active: rwdSubOpenIndex === i}]">
                  <router-link
                    :to="item.route"
                    class="txt-white"
                    v-for="(item, i) in item.subs"
                    :key=" `headerRwdSubNavItem${i}`">
                      {{item.name}}
                  </router-link>
                </div>
              </template>
            </div>

            <div class="txt-right">
              <div class="lang_link">
                <router-link :to="{ name: 'Home' }" class="small active">
                  <span>
                    繁體中文
                  </span>
                </router-link>
                <a href="https://www.kirineng.co.jp/" target="_blank" class="small">
                  <span>
                    日本語
                  </span>
                </a>
                <a href="https://www.kirineng.co.jp/corporate/global/en_about.html" target="_blank" class="small">
                  <span>
                    ENGLISH
                  </span>
                </a>
              </div>
            </div>
        </nav>

        <div
          class="hamburger"
          @click="toggleHamburger()"
          :class="[
            {
              'w': ifHamburgerOpen
            }
          ]">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </header>

    <div
      class="header-subcover"
      :class="{active: ifSubnavOpen}"
      @click="closeSubNav()">
    </div>

    <div
      class="header-subblock"
      :class="{active: ifSubnavOpen}">
        <div class="pic">
          <figure
            :style="{
              backgroundImage: `url(${headerSubPic})`
            }">
          </figure>
        </div>
        <div class="sub-nav">
          <router-link
            class="item"
            v-for="(item, i) in headerSubNav"
            :key="`headerSubNav${i}`"
            :to="item.route"
            @click.native="closeSubNav()">
              <div class="item-text">
                <h6>{{item.name}}</h6>
                <p class="txt-gray">{{item.enName}}</p>
              </div>
              <div class="arrow-circle"></div>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { headerNav } from '@/utils/const';

export default {
  name: 'Header',
  props: ['textColorOnTop'],
  data() {
    return {
      ifFixed: false,
      ifSubnavOpen: false,
      ifHamburgerOpen: false,
      headerNav,
      headerSubPic: null,
      headerSubNav: null,
      rwdSubOpenIndex: 4,
    };
  },
  computed: {
    ...mapState([
      'scrollDistence',
    ]),
  },
  watch: {
    $route() {
      console.log(123);
      this.ifFixed = false;
      this.ifSubnavOpen = false;
      this.ifHamburgerOpen = false;
      this.rwdSubOpenIndex = null;
    },
    scrollDistence() {
      if (window.innerWidth > 768) {
        if (this.scrollDistence > window.innerHeight) {
          this.ifFixed = true;
        } else {
          this.ifFixed = false;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.scrollDistence > 300) {
          this.ifFixed = true;
        } else {
          this.ifFixed = false;
        }
      }
    },
  },
  methods: {
    headerMainNavClick(i) {
      this.headerSubPic = headerNav[i].subPic;
      if (window.innerWidth > 1200) {
        if (this.headerNav[i].subs) {
          this.ifSubnavOpen = true;
          this.headerSubNav = this.headerNav[i].subs;
        } else {
          this.ifSubnavOpen = false;
          this.headerSubNav = null;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (i === this.rwdSubOpenIndex) {
          this.rwdSubOpenIndex = null;
        } else {
          this.rwdSubOpenIndex = i;
        }
      }
    },
    toggleHamburger() {
      this.ifHamburgerOpen = !this.ifHamburgerOpen;
    },
    closeSubNav() {
      this.ifSubnavOpen = false;
      this.headerSubPic = null;
      this.headerSubNav = null;
    },
  },
};
</script>

<style
  scoped
  src="@/assets/scss/page_header.scss"
  lang="scss">
</style>
