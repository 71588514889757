<template>
  <footer>
    <div class="footer-inner">
      <div class="inner w1400">
        <div class="footer-info">
          <img
            src="@/assets/img/public/02_footer/logo.svg"
            class="logo"
            alt="Kirin Engineering"
          >
          <p>
            服務專線 02-27816270<br>
            電子信箱 sales@kirineng.tw<br>
            週一至週五 9:00～12:00／13:00～18:00<br>
            地址 105404 台北市松山區復興北路57號16樓之2
          </p>
        </div>
        <ul class="footer-nav">
          <li
            v-for="(item, key) in footerNav"
            :key="key"
            class="footer-nav_item">
              <h6 class="txt-bold">{{item.title}}</h6>
              <router-link
                v-for="(link, key) in item.links"
                :key="key"
                :to="link.route">
                  {{link.name}}
              </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="inner w1400">
        <small>
          COPYRIGHT © 2021 KIRIN ENGINEERING COMPANY, LIMITED TAIPEI BRANCH, ALL RIGHTS RESERVED.
        </small>
        <router-link
          :to="
            {
              name: 'Privacy'
            }
          "
          class="small">
            隱私權及個資保護聲明
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import { footerNav } from '@/utils/const';

export default {
  name: 'Footer',
  data() {
    return {
      footerNav,
    };
  },
};
</script>

<style
  scoped
  src="@/assets/scss/page_footer.scss"
  lang="scss">
</style>
