<template>
  <button
    id="go_top_btn"
    type="button"
    aria-label="click to go top"
    :class="[btnShow ? 'active' : '']"
    @click="clickHandler"
  >
    <svg
      class="svg-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="24.52" height="24.52" viewBox="0 0 24.52 24.52">
        <g transform="translate(-419 -2068)">
        <circle
          cx="12.26" cy="12.26" r="12.26"
          transform="translate(419 2068)"
          fill="none"/>
        <path
          d="M2715.789,1791.683l3.489,3.489-3.489,3.489"
          transform="translate(-2285.897 284.71)"
          fill="none"
          stroke="" stroke-width="1.5"/>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'GoTopBtn',
  data() {
    return {
      btnShow: false,
      scrollRatio: 0,
    };
  },
  computed: {
    styleProgress() {
      return { transform: `translateY(calc(100% * (${this.scrollRatio} - 1)))` };
    },
  },
  methods: {
    scrollHandler() {
      const documentHeight = document.getElementsByTagName('body')[0].getBoundingClientRect().height;
      const portHeight = window.innerHeight;
      const scrollDistance = window.scrollY;

      this.scrollRatio = scrollDistance / (documentHeight - portHeight);

      if (scrollDistance > portHeight * 0.6) {
        this.btnShow = true;
      } else {
        this.btnShow = false;
      }
    },
    clickHandler() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>

<style lang="scss" scoped>
#go_top_btn {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: #fff;
  overflow: hidden;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
  .svg-arrow {
    transform: rotate(-90deg) scale(1.5);
    path {
      stroke: red;
    }
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      border: 1px solid #fff;
      background-color: red;
      .svg-arrow {
        path {
          stroke: #fff;
        }
      }
    }
  }
}
</style>
