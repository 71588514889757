import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*', // 當連到不是我們自訂義的路由時
    redirect: '/', // 重新指向路由回首頁
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'Home',
        },
      },
      {
        path: 'topMessage',
        name: 'TopMessage',
        meta: '經營理念',
        component: () => import('../components/page/about/TopMessage.vue'),
      },
      {
        path: 'history',
        name: 'History',
        meta: '沿革',
        component: () => import('../components/page/about/History.vue'),
      },
      {
        path: 'companyProfile',
        name: 'CompanyProfile',
        meta: '公司簡介',
        component: () => import('../components/page/about/CompanyProfile.vue'),
      },
      // {
      //   path: 'policy',
      //   name: 'OperationPolicy',
      //   meta: '經營方針',
      //   component: () => import('../components/page/about/OperationPolicy.vue'),
      // },
    ],
  },
  {
    path: '/newsList/:page',
    name: 'NewsList',
    props: true,
    component: () => import('../views/NewsList.vue'),
  },
  {
    path: '/newsInfo/:id',
    name: 'NewsInfo',
    props: true,
    component: () => import('../views/NewsInfo.vue'),
  },
  {
    path: '/service/:type',
    name: 'Service',
    props: true,
    component: () => import('../views/Service.vue'),
  },
  {
    path: '/process',
    name: 'Process',
    component: () => import('../views/Process.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'Home',
        },
      },
      {
        path: 'integrationProgramme',
        name: 'IntegrationProgramme',
        meta: '整合方案',
        component: () => import('../components/page/process/IntegrationProgramme.vue'),
      },
      {
        path: 'keyPoint',
        name: 'KeyPoint',
        meta: '成功關鍵',
        component: () => import('../components/page/process/KeyPoint.vue'),
      },
      {
        path: 'techniqueProcess',
        name: 'TechniqueProcess',
        meta: '技術流程',
        component: () => import('../components/page/process/TechniqueProcess.vue'),
      },
    ],
  },
  {
    path: '/achievement',
    name: 'Achievement',
    redirect: {
      name: 'AchievementPast',
      params: { page: 1 },
    },
    component: () => import('../views/Achievement.vue'),
    children: [
      {
        path: 'past/:page',
        name: 'AchievementPast',
        meta: '歷年實績',
        props: true,
        component: () => import('../components/page/achievement/Past.vue'),
      },
      {
        path: 'myanmar',
        name: 'Myanmar',
        meta: '緬甸啤酒廠',
        component: () => import('../components/page/achievement/AchievementInfo.vue'),
      },
      {
        path: 'vietnam',
        name: 'Vietnam',
        meta: '越南麒麟飲料公司',
        component: () => import('../components/page/achievement/AchievementInfo.vue'),
      },
      {
        path: 'taiwan',
        name: 'Taiwan',
        meta: '台灣菸酒公司',
        component: () => import('../components/page/achievement/AchievementInfo.vue'),
      },
      {
        path: 'koikeya',
        name: 'Koike',
        meta: '越南湖池屋公司',
        component: () => import('../components/page/achievement/AchievementInfo.vue'),
      },
    ],
  },
  {
    path: '/contact/:type',
    name: 'Contact',
    props: true,
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    props: true,
    component: () => import('../views/PrivacyTerm.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(from, to) {
    console.log(from, to);
    return (from.name !== to.name)
      ? { x: 0, y: 0 }
      : '';
  },
});

console.log(process.env);

export default router;
