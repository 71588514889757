<template>
  <div id="app">
    <Header
      :textColorOnTop="(() => {
        return (
          this.$route.name === 'Home'
          || this.$route.name === 'Service'
          || this.$route.name === 'Myanmar'
          || this.$route.name === 'Vietnam'
          || this.$route.name === 'Taiwan'
          || this.$route.name === 'Koike'
        )
          ? 'white'
          : '';
      })()"/>

    <router-view/>

    <Footer/>

    <GoTopBtn/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import GoTopBtn from '@/components/GoTopBtn.vue';
import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoTopBtn,
  },
  methods: {
    ...mapActions([
      'listenScrollDistence',
    ]),
  },
  data() {
    return {
    };
  },
  mounted() {
    this.listenScrollDistence();
  },
};
</script>

<style>
  @font-face {
    font-family: 'AvenirNext';
    src: url('./assets/fonts/AvenirNextLTPro-Medium.otf') format('opentype'),
        url('./assets/fonts/AvenirNextLTPro-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('./assets/fonts/AvenirNextLTPro-Bold.otf') format('opentype'),
        url('./assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirNext';
    src: url('./assets/fonts/AvenirNextLTPro-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  .txt-an{
    font-family: "AvenirNext", "Noto Sans TC";
  }
</style>
