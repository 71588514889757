import indexAchievementPicSwiper01 from '../assets/img/index/05_img.jpg';
import indexAchievementPicSwiper02 from '../assets/img/index/05_img2.png';
// eslint-disable-next-line import/no-duplicates
import indexAboutPic01 from '../assets/img/index/02_img1.jpg';
import indexAboutPic02 from '../assets/img/index/02_img2.jpg';
import indexAboutPic03 from '../assets/img/index/02_img3.jpg';
// import indexAboutPic04 from '../assets/img/index/02_img4.jpg';
import indexServicePicSwiper01 from '../assets/img/index/03_BEVERAGE.jpg';
import indexServicePicSwiper02 from '../assets/img/index/03_FOODS.jpg';
import indexServicePicSwiper03 from '../assets/img/index/03_medicine.jpg';
import indexServicePicSwiper04 from '../assets/img/index/03_OTHERS.jpg';
import indexServiceIcon from '../assets/img/index/03_FOODS_icon.svg';

// eslint-disable-next-line import/no-duplicates
import headerPic01 from '../assets/img/index/02_img1.jpg';
import headerPic02 from '../assets/img/service/Service_2.jpg';
import headerPic03 from '../assets/img/process/Process_3.jpg';
import headerPic04 from '../assets/img/achievement/01_banner.jpg';
import headerPic05 from '../assets/img/contact/01_banner.jpg';

export const footerNav = [
  {
    title: '關於我們',
    links: [
      {
        name: '公司理念',
        route: {
          name: 'TopMessage',
        },
      },
      {
        name: '沿革',
        route: {
          name: 'History',
        },
      },
      {
        name: '公司簡介',
        route: {
          name: 'CompanyProfile',
        },
      },
      // {
      //   name: '經營方針',
      //   route: {
      //     name: 'OperationPolicy',
      //   },
      // },
    ],
  },
  {
    title: '服務項目',
    links: [
      {
        name: '飲料',
        route: {
          name: 'Service',
          params: { type: 'beverage' },
        },
      },
      {
        name: '食品',
        route: {
          name: 'Service',
          params: { type: 'foods' },
        },
      },
      {
        name: '生物藥品',
        route: {
          name: 'Service',
          params: { type: 'medicine' },
        },
      },
      {
        name: '其他',
        route: {
          name: 'Service',
          params: { type: 'other' },
        },
      },
    ],
  },
  {
    title: '服務流程',
    links: [
      {
        name: '整合方案',
        route: {
          name: 'IntegrationProgramme',
        },
      },
      {
        name: '成功關鍵',
        route: {
          name: 'KeyPoint',
        },
      },
      {
        name: '技術流程',
        route: {
          name: 'TechniqueProcess',
        },
      },
    ],
  },
  {
    title: '實績介紹',
    links: [
      {
        name: '歷年實績',
        route: {
          name: 'AchievementPast',
          params: { page: 1 },
        },
      },
      {
        name: '緬甸啤酒廠',
        route: {
          name: 'Myanmar',
        },
      },
      {
        name: '臺灣菸酒公司',
        route: {
          name: 'Taiwan',
        },
      },
      {
        name: '越南麒麟飲料公司',
        route: {
          name: 'Vietnam',
        },
      },
      {
        name: '越南湖池屋公司',
        route: {
          name: 'Koike',
        },
      },
    ],
  },
  {
    title: '最新消息',
    links: [
      {
        name: '全部',
        route: {
          name: 'NewsList',
          params: { page: 1 },
        },
      },
    ],
  },
  {
    title: '聯絡我們',
    links: [
      {
        name: '企業合作',
        route: {
          name: 'Contact',
          params: {
            type: 'company',
          },
        },
      },
      {
        name: '員工招募',
        route: {
          name: 'Contact',
          params: {
            type: 'recruit',
          },
        },
      },
    ],
  },
];

export const headerNav = [
  {
    title: '關於我們',
    subPic: headerPic01,
    subs: [
      {
        name: '經營理念',
        enName: 'TOP MESSAGE',
        route: {
          name: 'TopMessage',
        },
      },
      {
        name: '公司簡介',
        enName: 'COMPANY PROFILE',
        route: {
          name: 'CompanyProfile',
        },
      },
      {
        name: '沿革',
        enName: 'HISTORY',
        route: {
          name: 'History',
        },
      },
      // {
      //   name: '經營方針',
      //   enName: 'POLICY',
      //   route: {
      //     name: 'OperationPolicy',
      //   },
      // },
    ],
  },
  {
    title: '最新消息',
    route: {
      name: 'NewsList',
      params: { page: 1 },
    },
  },
  {
    title: '服務項目',
    subPic: headerPic02,
    subs: [
      {
        name: '飲料',
        enName: 'BEVERAGE',
        route: {
          name: 'Service',
          params: { type: 'beverage' },
        },
      },
      {
        name: '食品',
        enName: 'FOODS',
        route: {
          name: 'Service',
          params: { type: 'foods' },
        },
      },
      {
        name: '生物藥品',
        enName: 'MEDICINE',
        route: {
          name: 'Service',
          params: { type: 'medicine' },
        },
      },
      {
        name: '其他',
        enName: 'OTHER',
        route: {
          name: 'Service',
          params: { type: 'other' },
        },
      },
    ],
  },
  {
    title: '服務流程',
    subPic: headerPic03,
    subs: [
      {
        name: '整合方案',
        enName: 'INTEGRATION PROGRAMME',
        route: {
          name: 'IntegrationProgramme',
        },
      },
      {
        name: '成功關鍵',
        enName: 'KEY POINTS OF SUCCESS',
        route: {
          name: 'KeyPoint',
        },
      },
      {
        name: '技術流程',
        enName: 'TECHNIQUE PROCESS',
        route: {
          name: 'TechniqueProcess',
        },
      },
    ],
  },
  {
    title: '實績介紹',
    subPic: headerPic04,
    subs: [
      {
        name: '歷年實績',
        enName: 'ACTUAL ACHIEVEMENT',
        route: {
          name: 'AchievementPast',
          params: { page: 1 },
        },
      },
      {
        name: '緬甸啤酒公司',
        enName: 'MYANMAR BREWERY LIMITED',
        route: {
          name: 'Myanmar',
        },
      },
      {
        name: '臺灣菸酒公司',
        enName: 'TAIWAN TOBACCO AND LIQUOR CORPORATION',
        route: {
          name: 'Taiwan',
        },
      },
      {
        name: '越南麒麟飲料公司',
        enName: 'VIETNAM KIRIN BEVERAGE CO., LTD.',
        route: {
          name: 'Vietnam',
        },
      },
      {
        name: '越南湖池屋公司',
        enName: 'KOIKE-YA VIETNAM CO. LTD.',
        route: {
          name: 'Koike',
        },
      },
    ],
  },
  {
    title: '聯絡我們',
    subPic: headerPic05,
    subs: [
      {
        name: '企業合作',
        enName: 'CONTACT US',
        route: {
          name: 'Contact',
          params: {
            type: 'company',
          },
        },
      },
      {
        name: '員工招募',
        enName: 'JOIN US',
        route: {
          name: 'Contact',
          params: {
            type: 'recruit',
          },
        },
      },
    ],
  },
];

export const indexAboutData = [
  {
    route: {
      name: 'TopMessage',
    },
    pic: indexAboutPic01,
    title: '經營理念',
    titleEn: 'Top Message',
  },
  {
    route: {
      name: 'CompanyProfile',
    },
    pic: indexAboutPic02,
    title: '公司簡介',
    titleEn: 'Company Profile',
  },
  {
    route: {
      name: 'History',
    },
    pic: indexAboutPic03,
    title: '沿革',
    titleEn: 'History',
  },
  // {
  //   route: {
  //     name: 'OperationPolicy',
  //   },
  //   pic: indexAboutPic04,
  //   title: '經營方針',
  //   titleEn: 'Policy',
  // },
];

export const indexAchievementPicSwiper = [
  indexAchievementPicSwiper02,
  indexAchievementPicSwiper01,
];

export const indexServicePicSwiper = [
  {
    icon: indexServiceIcon,
    pic: indexServicePicSwiper01,
    name: '飲料',
    nameEn: 'Drinks',
    info: '調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/',
    route: {
      name: 'Service',
      params: { type: 'beverage' },
    },
  },
  {
    icon: indexServiceIcon,
    pic: indexServicePicSwiper02,
    name: '食物',
    nameEn: 'Foods',
    info: '調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/',
    route: {
      name: 'Service',
      params: { type: 'foods' },
    },
  },
  {
    icon: indexServiceIcon,
    pic: indexServicePicSwiper03,
    name: '生物藥品',
    nameEn: 'Medicine',
    info: '調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/',
    route: {
      name: 'Service',
      params: { type: 'medicine' },
    },
  },
  {
    icon: indexServiceIcon,
    pic: indexServicePicSwiper04,
    name: '其他',
    nameEn: 'Other',
    info: '調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/調味品/動植物油/油脂/',
    route: {
      name: 'Service',
      params: { type: 'other' },
    },
  },
];
