import Vue from 'vue';
import VueScrollmagic from 'vue-scrollmagic';
// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Smooth Scroll behavior polyfill
import smoothscroll from 'smoothscroll-polyfill';

import App from './App.vue';
import router from './router';
import store from './store';

smoothscroll.polyfill();

Vue.use(VueScrollmagic);
Vue.use(VueAwesomeSwiper);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // eslint-disable-next-line no-proto
    this.__proto__.$customSWAL = (
      {
        icon = 'success',
        title = '',
        text = null,
        thenFunc = null,
        confirmButtonText = '確定',
        html = null,
      } = {},
    ) => {
      this.$swal.fire({
        position: 'center-center',
        icon,
        title: title || 'message',
        text,
        html,
        confirmButtonText,
        showClass: { popup: 'animated fadeInDown faster' },
        hideClass: { popup: 'animated fadeOutUp faster' },
      }).then(() => {
        if (thenFunc) {
          thenFunc();
        }
      });
    };
  },
}).$mount('#app');
