export const listenScrollDistence = ({ commit }) => {
  window.addEventListener(
    'scroll',
    () => {
      commit('setScrollDistence', window.scrollY);
    },
    true,
  );
};

export const temp = () => {};
