var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('Header',{attrs:{"textColorOnTop":(function () {
      return (
        this$1.$route.name === 'Home'
        || this$1.$route.name === 'Service'
        || this$1.$route.name === 'Myanmar'
        || this$1.$route.name === 'Vietnam'
        || this$1.$route.name === 'Taiwan'
        || this$1.$route.name === 'Koike'
      )
        ? 'white'
        : '';
    })()}}),_c('router-view'),_c('Footer'),_c('GoTopBtn')],1)}
var staticRenderFns = []

export { render, staticRenderFns }